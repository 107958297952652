/*!
 * main.scss
 * Author - Brendan Smith 
 */

// Imports overrides and regular bootstrap
// Omits the need for regular bootstrap.scss
//
@import "./bsatheme";
// Bootstrap next!
// Customise which imports are need for this project, reduces overall CSS filesize.
@import "../../../node_modules/bootstrap/scss/bootstrap";
// imports particular theme tweaks
@import "./bsathemeextras";
//
//